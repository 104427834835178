.header__search {
  background: white;
  border-radius: 2px;
  /* width: clamp(20rem, 45vw, 35.25rem); */
  max-width: 564px;
  width: 100%;
  /* min-width: 304px; */
  /* height: 56px; */
}
.header__search .MuiSvgIcon-root {
  color: var(--blue);
  margin: 0 5px;
}
.header__search input {
  border: none;
  padding: 10px;
  /* height: 36px; */
  border-radius: 2px;
  outline: none;
  width: 100%;
}
button {
  color: var(--blue);
  border: none;
  outline: none;
  background: white;
  font-weight: 600;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px 40px 5px 40px;
  border: 1px solid #dbdbdb;
}
.Cart {
  cursor: pointer;
}
