.multiItemProductCarousel,
.multiItemProductCarouselXs {
  background: white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}
.multiItemProductCarousel {
  margin: 10px;
}
.multiItemProductCarouselXs {
  margin-bottom: 13px;
}
.homePage__carousel .slick-arrow.slick-prev,
.homePage__carousel .slick-arrow.slick-next {
  background: #fcfcfc;
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.homePage__carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.homePage__carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.homePage__carousel .custom-indicator {
  bottom: -65px;
}
.homePage__carousel .custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.homePage__carousel .custom-indicator li.slick-active {
  filter: grayscale(0%);
}
.homePage__carousel .multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}
.carousel__header,
.card_header {
  justify-content: space-between;
  padding: 15px;
}
.carousel__header {
  border-bottom: 1px solid rgb(223, 223, 223);
}
.carousel__header > h1,
.card_header > h1 {
  font-size: clamp(0.8rem, 3.3vw, 1.3rem);
  font-weight: 500;
}
.carousel__header > button,
.card_header > button {
  border: none;
  outline: none;
  padding: 12px 22px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  border-radius: 3px;
  text-transform: uppercase;
  background: var(--blue);
}

.homePage__carousel .slick-dots.slick-thumb {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
  background: rgba(0, 0, 0, 0.5);
  width: 88px;
  padding: 2px;
  border-radius: 20px;
  height: 15px;
  bottom: 20px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.homePage__carousel .slick-dots.slick-thumb li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: white;
}

.homePage__carousel .slick-dots.slick-thumb li.slick-active {
  background-color: white;
  width: 17px;
}
