.headerBottom {
  background: white;
  overflow-x: scroll;
  /* ?if you give justifycontnt property last and first image cannot be seen,half of it is going */
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 16%);
}
@media screen and (min-width: 600px) {
  .headerBottom {
    justify-content: center;
  }
}
.headerBottom::-webkit-scrollbar {
  display: none;
}
.headerBottom__item {
  flex-direction: column;
  margin-left: clamp(0.1rem, 2.5vw, 2.4rem);
  margin-right: clamp(0.1rem, 2.5vw, 2.4rem);
  margin-top: clamp(0.3rem, 2.5vw, 0.7rem);
  margin-bottom: clamp(0.3rem, 2.5vw, 0.7rem);
}
.header__bottomItemImage {
  overflow: hidden;
  border-radius: 50%;
  width: clamp(3rem, 6vw, 4rem);
  height: clamp(3rem, 6vw, 4rem);
}
.header__bottomItemImage > img {
  background-color: bisque;
  border-radius: 50%;
  width: 80%;
  height: 80%;
  /* ?clamp(min,value,max) */
  /* object-fit: contain; */
}
.headerBottom__item p {
  color: rgb(52, 52, 52);
  font-weight: 600;
  font-size: clamp(0.7rem, 2.2vw, 0.84rem);
}
.headerBottom__item:hover {
  cursor: pointer;
}
.headerBottom__item:hover p {
  color: #2874f0;
}
.tippyCon {
  border: 2px solid red;
}
