.smallImageBorder:nth-child(2n + 1) {
  border-right: 1px solid lightgray;
}
.smallImageBorder:not(.smallImageBorder:last-child) {
  border-bottom: 1px solid lightgray;
}
.card {
  padding: 10px;
  cursor: pointer;
  transition: 400ms;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 600) {
  .card:hover {
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  }
}
.card .dots {
  width: 10px;
  height: 10px;
  color: gray;
}
.card__image {
  width: clamp(5rem, 40vw, 13.5rem);
  display: flex;
  justify-content: center;
  /* height: 285px; */
  height: clamp(9rem, 30vh, 15rem);
  /* border: 2px solid; */
}
.card > .card__image > img {
  width: 100%;
  padding: 1.3rem;
  height: 100%;
  object-fit: contain;
}
.card__heart {
  color: lightgray;
  position: absolute;
  right: 7px;
  top: 17px;
}
.card__heart .MuiSvgIcon-root:hover {
  color: red;
}
.card__details > .title {
  font-weight: 500;
  font-size: 14px;
  color: rgb(165, 165, 165);
}
.card__details > p {
  font-size: 14px;
}
.card__details > .span1 {
  font-size: 16px;
  font-weight: 500;
}
.card__details > .span2 {
  color: gray;
  padding-left: 10px;
  text-decoration: line-through;
}
.card__details > .span3 {
  padding-left: 10px;
  font-weight: 600;
  color: #388e3c;
  font-size: 13px;
}

/* .productDet {
  position: relative;
  transition: 0.5s;
  height: 70px;
} 
 .productDet > .card__size {
  position: absolute;
  opacity: 0;
} 
 .card:hover .productDet {
  transition: 0.5s;
  transform: translateY(-20%);
} 
 .card:hover .productDet > .card__size {
  opacity: 1;
  transition: 1.75s;
} */
