* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: var(--blue) !important;
  color: white;
  font-weight: 700;
}

.app {
  background: #f1f3f6;
}
.flexStyle {
  display: flex;
  align-items: center;
}
.grayBorder {
  border: 1px solid lightgray;
}
.grayBorderBottom {
  border-bottom: 1px solid lightgray;
}
.grayBorderTop {
  border-top: 1px solid lightgray;
}
.thinBorder {
  border: 2px solid green;
}
.thickBorder {
  border: 5px solid green;
}
a {
  text-decoration: none;
  color: inherit;
}
